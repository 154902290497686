import { types } from "mobx-state-tree";
import { Page } from "./pages";
import { Shoreline } from "./shoreline";
import { Subscription } from "./subscriptions";

export const RootStore = types.model({
  page: types.optional(Page, {}),
  shoreline: types.optional(Shoreline, {}),
  subscription: types.optional(Subscription, {})
});
