import { flow, types } from "mobx-state-tree";
import {
  postSubscriptionLogin,
  postSubscriptionOtp,
  getUserSubscription,
  updatePaymentMethodsSubscription,
  updateSubscription,
  updateSubscriptionLicensePlate,
  updateUnpaidSubscription
} from "../api";

export const Subscription = types
  .model({
    data: types.optional(types.string, ""),
    message: types.optional(types.string, `Thank you!`),
  })
  .actions((self) => ({
    postLogin: flow(function* postLogin(payload) {
      try {
        const response = yield postSubscriptionLogin(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    postOtp: flow(function* postOtp(payload) {
      try {
        const response = yield postSubscriptionOtp(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    fetchUserSubscription: flow(function* fetchUserSubscription(token) {
      try {
        const response = yield getUserSubscription(token);
        return response;
      } catch (error) {
        return error;
      }
    }),
    UpdatePaymentMethodsSubscription: flow(
      function* UpdatePaymentMethodsSubscription(token, payload) {
   
        try {
          const response = yield updatePaymentMethodsSubscription(
            token,
            payload
          );
          return response;
        } catch (error) {
          return error;
        }
      }
    ),
    UpdateSubscription: flow(function* UpdateSubscription(token, payload) {

      try {
        const response = yield updateSubscription(token, payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    UpdateSubscriptionLicensePlate: flow(function* UpdateSubscriptionLicensePlate(token, payload) {
 
      try {
        const response = yield updateSubscriptionLicensePlate(token, payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    UpdateUnpaidSubscription: flow(function* UpdateUnpaidSubscription(payload) {

      try {
        const response = yield updateUnpaidSubscription(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
  }));
