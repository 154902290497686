import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Header from "./component/Headers";
// import Licenseplate from "./component/Licenseplate";
// import Home from "./component/Dashboard";
// import Footer from "./component/Footers";
// import Payment from "./component/payment";
// import Receipt from "./component/Receipt";
// import Success from "./component/Success";
// import Error from "./component/Error";
// import Shortly from "./component/Shortly";
// import WickedTuna from "./component/WickedTuna";

const Suspense = React.Suspense
const lazy = React.lazy

const Home = lazy(() => import("./component/Dashboard"));
const Shortly = lazy(() => import("./component/Shortly"));
const WickedTuna = lazy(() => import("./component/WickedTuna"));
const Licenseplate = lazy(() => import("./component/Licenseplate"));
const Payment = lazy(() => import("./component/payment"));
const PaymentPage = lazy(() => import("./component/payment/payment"));
const PaymentPageNew = lazy(() => import('./component/payment/payment-new'))
const PaymentPageHyattDesney = lazy(() => import('./component/payment/hyatt-shuttle'))
const PaymentPageCustom = lazy(() => import("./component/payment/payment-custom-new"))
const PaymentPageCustomTest = lazy(() => import("./component/payment/test-payment"))
const Receipt = lazy(() => import("./component/Receipt"));
const ReceiptDock = lazy(() => import("./component/Receipt/dock"));
const ReceiptBozeman = lazy(() => import('./component/Receipt/bozeman'))
const Success = lazy(() => import("./component/Success"));
const SuccessACH = lazy(() => import("./component/Success/ach"));
const Error = lazy(() => import('./component/Error'))
const ParkingPayment = lazy(() => import('./component/payment/parking-payment'))
const DemoPayment = lazy(() => import('./component/payment/demo-payment'))
const FileUpload = lazy(() => import("./component/FileUpload"));
const ChatBotPaymentForm = lazy(() => import("./component/payment/chatbot"));
const ReceiptNew = lazy(() => import("./component/Receipt/receipt-new"));
const ChatbotSuccess = lazy(() => import("./component/Success/chatbot"));
const ChatbotReceipt = lazy(() =>
  import("./component/Receipt/chatbot-receipt")
);
const ChatBotLoginForm = lazy(() => import("./component/chatbot/login"))
const ChatBotDashboard = lazy(() => import("./component/chatbot/dashboard"))
/////////////// New Receipts ////////////////////
const NewReceiptIndex = lazy(() => import("./component/newInvoice/index"))
const NewReceiptSps = lazy(() => import("./component/newInvoice/sps"))
const NewReceiptDock = lazy(() => import("./component/newInvoice/dock"))
const NewReceiptBozeman = lazy(() => import("./component/newInvoice/bozeman"))
const NewReceiptPca = lazy(() => import("./component/newInvoice/pca"))
const NewReceiptXpress = lazy(() => import("./component/newInvoice/xpress"))
const NewReceiptHyatt = lazy(() => import('./component/newInvoice/hyatt'))
const NewReceiptDriveHospitality = lazy(() => import('./component/newInvoice/drivehospitality'))
const NewReceiptParkPlace = lazy(() => import('./component/newInvoice/parkplace'))
const SubscriptionLogin = lazy(() => import("./component/Subscription"));
const SubscriptionHome = lazy(() => import("./component/Subscription/home"));
const NewSubscriptionInvoice = lazy(() => import("./component/SubscriptionInvoice/index"))
const SubscriptionInvoice = lazy(() => import("./component/SubscriptionInvoice/invoice"))
////////////////////////////////////////////////
const RoutePath = (props) => {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                textAlign: 'center',
                margin: 0,
                fontSize: '1.2rem',
                fontWeight: 500,
                fontFamily: 'Nunito,sans-serif',
                lineHeight: 1.6
              }}
            >
              Please wait..!
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/payment" element={<Payment {...props} />} />
            <Route path="/paymentpage" element={<PaymentPage {...props} />} />
            <Route path="/paymentpage-new" element={<PaymentPageNew {...props} />} />
            <Route path="/hyatt-shuttle-payment/:id" element={<PaymentPageHyattDesney {...props} />} />
            <Route path="/paymentpage-custom" element={<PaymentPageCustom {...props} />} />
            <Route path="/paymentpage-custom-test" element={<PaymentPageCustomTest {...props} />} />
            <Route path="/receipt/:id" element={<Receipt {...props} />} />
            <Route path="/dock/receipt/:id" element={<ReceiptDock {...props} />} />
            <Route path="/success" element={<Success {...props} />} />
            <Route path="/success-ach" element={<SuccessACH {...props} />} />
            <Route path="/error" element={<Error {...props} />} />
            <Route path="/licenseplate" element={<Licenseplate {...props} />} />
            <Route path="/wickedtuna" element={<WickedTuna {...props} />} />
            <Route path="/s/:id" element={<Shortly {...props} />} />
            <Route path="/dock/s/:id" element={<Shortly {...props} />} />
            <Route path="/baxter/receipt/:id" element={<ReceiptBozeman {...props} />} />
            <Route path="/parking-payment-s/:id" element={<ParkingPayment {...props} />} />
            <Route path="/demo-payment/:id" element={<DemoPayment {...props} />} />
            <Route path="/receipt-new" element={<ReceiptNew {...props} />} />
            <Route path="/file-upload" element={<FileUpload {...props} />} />
            <Route
              path="/chatbot-payment/:id"
              element={<ChatBotPaymentForm {...props} />}
            />
            <Route
              path="/chatbot-success"
              element={<ChatbotSuccess {...props} />}
            />
            <Route
              path="/chatbot-receipt/:id"
              element={<ChatbotReceipt {...props} />}
            />
            <Route
              path="/chatbot-login"
              element={<ChatBotLoginForm {...props} />}
            />
            <Route
              path="/dashboard"
              element={<ChatBotDashboard {...props} />}
            />
            <Route path="/r/:id" element={<NewReceiptIndex {...props} />} />
            <Route path="/spsreceipt" element={<NewReceiptSps {...props} />} />
            <Route path="/pcareceipt" element={<NewReceiptPca {...props} />} />
            <Route path="/xpressreceipt" element={<NewReceiptXpress {...props} />} />
            <Route
              path="/dockreceipt"
              element={<NewReceiptDock {...props} />}
            />
            <Route
              path="/bozemanreceipt"
              element={<NewReceiptBozeman {...props} />}
            />
            <Route
              path="/invoice-hyatt"
              element={<NewReceiptHyatt {...props} />}
            />
            <Route
              path="/drive-hospitality"
              element={<NewReceiptDriveHospitality {...props} />}
            />
            <Route
              path="/parkplace"
              element={<NewReceiptParkPlace {...props} />}
            />
            <Route path="/a/:id" element={<NewSubscriptionInvoice {...props} />} />
            <Route
            path="/subscriptioninvoice"
            element={<SubscriptionInvoice {...props} />} 
            />
            <Route path="/s/login" element={<SubscriptionLogin {...props} />} />
            <Route path="/s/home" element={<SubscriptionHome {...props} />} />
          
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
export default RoutePath;
