import { types } from 'mobx-state-tree'

export const Page = types
  .model({
    pageNumber: types.optional(types.number, 1)
  })
  .actions(self => ({
    changePage(page) {
      self.pageNumber = page
    }
    
  }))
