import axios from "axios";

const ApiEndPoint = `${process.env.REACT_APP_APIENDPOINT}`;

export const postLicensePlate = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/shoreline/licenseplate`,
    payload
  );
  return responseBody ? responseBody : {};
};
export const postPayment = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/shoreline/chatbot-payments`,
    payload
  );
  return responseBody ? responseBody : {};
};
export const postPaymentText2ParkLA = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/text2parkla/payment`,
    payload
  );
  return responseBody ? responseBody : {};
};
export const postPaymentCustomStrip = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/dock/payments-monthly-strip`,
    payload
  );
  return responseBody ? responseBody : {};
};
export const getShortly = async (shortlyId) => {
  const { data: responseBody } = await axios.get(
    `${ApiEndPoint}/aes/shortly?id=${shortlyId}`
  );
  return responseBody ? responseBody : {};
};
export const getPaymentMethods = async (mobile, email) => {
  const { data: responseBody } = await axios.get(
    `${ApiEndPoint}/shoreline/get-paymentMethods?${mobile ? 'mobile=' + mobile : 'email=' + email}`
  );
  return responseBody ? responseBody : {};
};

export const removePaymentMethod = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/shoreline/delete-paymentMethods`,
    payload
  );
  return responseBody ? responseBody : {};
};

export const getSession = async (sessionId) => {
  const { data: responseBody } = await axios.get(
    `${ApiEndPoint}/parking-api/get-session?sessionId=${sessionId}`
  );
  return responseBody ? responseBody : {};
};

export const postParkingPayment = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/parking-api/payment-stripe`,
    payload
  );
  return responseBody ? responseBody : {};
};
export const postHyattPayment = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/parking-api/payment-stripe-hyatt`,
    payload
  );
  return responseBody ? responseBody : {};
};

export const postFileUpload = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/fulfillment/file-upload`,
    payload
  );
  return responseBody ? responseBody : {};
};

export const postPaymentMethods = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/chatbot/paymentMethod`,
    payload
  )
  return responseBody ? responseBody : {}
}
export const updatePaymentMethods = async (payload) => {
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/chatbot/update-paymentMethod`,
    payload
  )
  return responseBody ? responseBody : {}
}
export const getPaymentMethodsChatbot = async (userId) => {
  const { data: responseBody } = await axios.get(
    `${ApiEndPoint}/chatbot/get-paymentMethods?userId=${userId}`
  )
  return responseBody ? responseBody : {}
}
