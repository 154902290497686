import { observer } from 'mobx-react-lite';
import Routes from "./Routes";
import { RootStore } from './store'
import "./App.scss";

const store = RootStore.create({
  page: {},
  shoreline: {}
})
const App = observer(props => {

  return (
    <>
      <Routes {...props} store={store} />
    </>
  );
})

export default App;
