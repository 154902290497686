import { flow, getSnapshot, types } from "mobx-state-tree";
import {
  postLicensePlate,
  postPayment,
  getShortly,
  postPaymentText2ParkLA,
  getPaymentMethods,
  removePaymentMethod,
  getSession,
  postParkingPayment,
  postFileUpload,
  postPaymentMethods,
  updatePaymentMethods,
  getPaymentMethodsChatbot,
  postPaymentCustomStrip,
  postHyattPayment
} from "../api"

export const paymentMethod = types.model({
  id: types.optional(types.string, ""),
  last4: types.optional(types.string, ""),
  brand: types.optional(types.string, ""),
  exp_month: types.optional(types.number, 0),
  exp_year: types.optional(types.number, 0),
});

export const Shoreline = types
  .model({
    mobile: types.optional(types.string, ""),
    amount: types.optional(types.string, ""),
    userId: types.optional(types.string, ""),
    placeId: types.optional(types.string, ""),
    source: types.optional(types.string, ""),
    licensePlate: types.optional(types.string, ""),
    rateType: types.optional(types.string, ""),
    hours: types.optional(types.number, 0),
    extension: types.optional(types.string, "false"),
    extensionFor: types.optional(types.string, ""),
    confirmationMessage: types.optional(types.string, `Thank you!`),
    paymentMethods: types.optional(types.array(paymentMethod), []),
  })
  .actions((self) => ({
    savePayload(payload) {
      self.mobile = payload.mobile;
      self.amount = payload.total;
      self.userId = payload.userId;
      self.placeId = payload.placeId;
      self.source = payload.source;
      self.licensePlate = payload.licensePlate;
      self.rateType = payload.rateType;
      self.hours = payload.hours;
      self.extension = payload.extension;
      self.extensionFor = payload.extensionFor;
      self.paymentMethods = payload.paymentMethods;
    },
    getPayload() {
      return {
        mobile: self.mobile,
        amount: self.amount,
        userId: self.userId,
        placeId: self.placeId,
        source: self.source,
        licensePlate: self.licensePlate,
        rateType: self.rateType,
        hours: self.hours,
        extension: self.extension,
        extensionFor: self.extensionFor,
        confirmationMessage: self.confirmationMessage,
        paymentMethods: getSnapshot(self.paymentMethods),
      };
    },
    LicensePlate: flow(function* LicensePlate(payload) {
      try {
        const response = yield postLicensePlate(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostPayment: flow(function* PostPayment(payload) {
      try {
        const response = yield postPayment(payload);
        if (response && response.success) {
          self.confirmationMessage = response.confirmationMessage;
        }
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostPaymentText2ParkLA: flow(function* PostPaymentText2ParkLA(payload) {
      try {
        const response = yield postPaymentText2ParkLA(payload);
        if (response && response.success) {
          self.confirmationMessage = response.confirmationMessage;
        }
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostPaymentCustomStrip: flow(function* PostPaymentCustomStrip(payload) {
      try {
        const response = yield postPaymentCustomStrip(payload);
        if (response && response.success) {
          self.confirmationMessage = response.confirmationMessage;
        }
        return response;
      } catch (error) {
        return error;
      }
    }),

    GetShortly: flow(function* GetShortly(shortlyId) {
      try {
        const response = yield getShortly(shortlyId);
        return response;
      } catch (error) {
        return error;
      }
    }),
    GetPaymentMethods: flow(function* GetPaymentMethods(mobile, email) {
      try {
        const response = yield getPaymentMethods(mobile, email);
        return response;
      } catch (error) {
        return error;
      }
    }),
    RemovePaymentMethod: flow(function* RemovePaymentMethod(payload) {
      try {
        const response = yield removePaymentMethod(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    GetSession: flow(function* GetSession(payload) {
      try {
        const response = yield getSession(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostParkingPayment: flow(function* PostParkingPayment(payload) {
      try {
        const response = yield postParkingPayment(payload);
        if (response && response.success) {
          self.confirmationMessage = response.confirmationMessage;
        }
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostHyattPayment: flow(function* PostHyattPayment(payload) {
      try {
        const response = yield postHyattPayment(payload);
        if (response && response.success) {
          self.confirmationMessage = response.confirmationMessage;
        }
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostFileUpload: flow(function* PostFileUpload(payload) {
      try {
        const response = yield postFileUpload(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    PostPaymentMethods: flow(function* PostPaymentMethods(payload) {
      try {
        const response = yield postPaymentMethods(payload);
        return response;
      } catch (error) {
        return error;
      }
    }),
    UpdatePaymentMethods: flow(function* UpdatePaymentMethods(payload) {
      try {
        const response = yield updatePaymentMethods(payload)
        return response
      } catch (error) {
        return error
      }
    }),
    GetPaymentMethodsChatbot: flow(function* GetPaymentMethodsChatbot(payload) {
      try {
        const response = yield getPaymentMethodsChatbot(payload)
        return response
      } catch (error) {
        return error
      }
    }),
  }))
